<template>
  <pp-form @submit.prevent="handleSubmit">
    <template v-slot="{ disabled }">
      <hr />

      <pp-card-body>
        <div class="space-y-4">
          <pp-token-amount-input
            key="1"
            ref="tokenAmountInput1"
            :address="market.baseToken.address"
            show-token
            :value="baseAmount"
            @input="handleInput(market.baseToken.address, $event)"
            data-test="base-liquidity-input"
            :rules="[(val) => val > 0 || 'Amount must be larger than 0']"
          ></pp-token-amount-input>

          <div class="text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </div>

          <pp-token-amount-input
            key="2"
            ref="tokenAmountInput2"
            :address="market.quoteToken.address"
            show-token
            :value="quoteAmount"
            @input="handleInput(market.quoteToken.address, $event)"
            data-test="quote-liquidity-input"
            :rules="[(val) => val > 0 || 'Amount must be larger than 0']"
          ></pp-token-amount-input>
        </div>
      </pp-card-body>

      <template v-if="details.is('resolved')">
        <hr />

        <pp-card-body>
          <pp-simple-data>
            <template #left>Approx. share of pool</template>
            <template #right>
              <div class="font-semibold">
                <pp-formatted-number
                  data-test="approx-share"
                  :value="100 * details.response.shareOfPool"
                  :max-decimal="8"
                ></pp-formatted-number>
                %
              </div>
            </template>
          </pp-simple-data>
        </pp-card-body>
      </template>

      <pp-card-actions>
        <pp-token-approve-container
          :spender-address="market.spenderAddress"
          :spending-amount="baseTokenAmount"
        >
          <template v-slot:default="baseToken">
            <pp-token-approve-container
              v-if="baseToken.approved"
              :spender-address="market.spenderAddress"
              :spending-amount="quoteTokenAmount"
            >
              <template v-slot:default="{ approved }">
                <pp-btn
                  v-if="approved"
                  size="xl"
                  class="flex w-full"
                  type="submit"
                  :disabled="disabled"
                  data-test="btn"
                  :loading="details.is('loading') || form.is('loading')"
                >
                  Add Liquidity
                </pp-btn>
              </template>
            </pp-token-approve-container>
          </template>
        </pp-token-approve-container>
      </pp-card-actions>
    </template>
  </pp-form>
</template>

<script>
  import Vue from 'vue'
  import TokenAmount from '@/domains/entities/TokenAmount'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import { generateEtherscanTxUrl, formatNumber } from '@/assets/helpers'

  const debounceTime = 500

  export default Vue.extend({
    props: {
      market: { type: Object, required: true },
    },
    data() {
      return {
        baseAmount: '',
        quoteAmount: '',
        timeout: null,
        interval: null,
        details: createState(),
        form: createState(),
      }
    },
    computed: {
      quoteTokenAmount() {
        return new TokenAmount(this.market.quoteToken, this.quoteAmount || 0, false)
      },
      baseTokenAmount() {
        return new TokenAmount(this.market.baseToken, this.baseAmount || 0, false)
      },
    },
    methods: {
      handleInput(address, value) {
        const activeToken = this.market.baseToken.address === address ? 'base' : 'quote'
        const inactiveToken = this.market.baseToken.address === address ? 'quote' : 'base'
        this[`${activeToken}Amount`] = value
        this[`${inactiveToken}Amount`] = ''
        clearTimeout(this.timeout)
        clearInterval(this.interval)

        this.timeout = setTimeout(() => {
          new PromiseHandler(
            () =>
              this.market.contract(this.$store.getters['wallet/identity']).addDualDetails({
                tokenAmount: new TokenAmount(this.market[`${activeToken}Token`], value || 0, false),
                slippage: this.$app.state.slippage,
              }),
            this.details
          )
            .execute({ force: true })
            .then((response) => {
              this[`${inactiveToken}Amount`] = response.otherTokenAmount.formattedAmount()

              this.$refs.tokenAmountInput1 && (this.$refs.tokenAmountInput1.interacted = true)
              this.$refs.tokenAmountInput2 && (this.$refs.tokenAmountInput2.interacted = true)

              this.interval = setInterval(() => this.handleInput(address, value), 60000)
            })
        }, debounceTime)
      },
      handleSubmit() {
        new PromiseHandler(
          () =>
            this.market.contract(this.$store.getters['wallet/identity']).addDual({
              tokenAmounts: [this.baseTokenAmount, this.quoteTokenAmount],
              slippage: this.$app.state.slippage,
            }),
          this.form
        )
          .execute()
          .then((response) => {
            const inputTokenNames = this.market.tokenPair.map((token) => token.name)
            this.$notification.success({
              title: 'Add liquidity',
              text: `Added ${formatNumber(this.baseAmount)} ${
                inputTokenNames[0]
              } and ${formatNumber(this.quoteAmount)} ${inputTokenNames[1]}.`,
              action: {
                url: generateEtherscanTxUrl(response.hash),
                urlText: 'View on explorer',
              },
            })

            this.$emit('success', response)
          })
          .catch(() => {
            this.$notification.error({
              title: 'Add liquidity',
              text: `Unable to add liquidity to ${this.market.token.name}`,
            })
          })
      },
    },
  })
</script>
